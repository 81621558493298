<template>
  <div class="home page-wrapper">
    <div class="container-xl">
      <!-- Page title -->
      <div class="page-header d-print-none">
        <div class="row align-items-center">
          <div class="col">
            <!-- Page pre-title -->
            <div class="page-pretitle">
              {{ $auth.activeCompany.companyName }}
            </div>
            <h2 class="page-title">Press Releases</h2>
          </div>
        </div>
      </div>
    </div>
    <div class="page-body">
      <div class="container-xl" v-if="loaded">
        <div class="row row-deck row-cards">
          <div class="card card-md">
            <div class="card-body">
              <div class="row">
                <div class="markdown">
                  <p class="h2">Press Releases</p>
                  <p>
                    Keep buyers informed with news-worthy and in-depth updates
                    about your business. Your press release headline will appear
                    in Business Chatter RSS Feeds and a link to view the press
                    release will appear on your company profile in each
                    directory it is posted in.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="card card-md" v-if="showForm">
            <div class="card-body">
              <div class="row">
                <div class="col-12 mb-3">
                  <p class="h2">Create Your Press Release</p>
                  <div>
                    <label class="form-label">Title:</label>
                    <input :class="[
                'form-control',
                this.formErrors.headline ? 'is-invalid' : '',
              ]" id="validateHeadline" ref="headline" v-model="headline" required @blur="validate('headline')" />
                    <div class="invalid-feedback">
                      <small>Please enter a headline</small>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  Enter/Edit your press release text:
                  <br />
                  NOTE: All special fonts, images, formatting tags, and tables
                  will be removed
                  <tiny-mce v-model="fileHtml" :class="[
                'form-control',
                this.formErrors.body ? 'is-invalid' : '',
              ]" toolbarValues="undo redo | link unlink" id="validateBody" ref="body" required
                    v-on:update:selected="validate(`body`)"></tiny-mce>
                  <div class="invalid-feedback">
                    <small>Please paste the press release body</small>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card card-md">
            <div class="card-body">
              <div class="row">
                <div class="markdown">
                  <div class="col-12 card card-md" v-if="paymentSuccess">
                    <div class="card-status-top bg-success"></div>
                    <div class="card-body">
                      <div class="col-12">
                        <p class="h3">Your order has been received</p>
                        <p>
                          Thank you for letting us serve your marketing needs.
                          It's our pleasure to assist you whenever you need us.
                        </p>
                        <p>
                          Your press release should now appear on your company
                          profile in the directories you selected. It will be
                          reviewed for quality control purposes and you will be
                          contacted if we need to make any changes.
                        </p>
                        <p v-if="orderTotal > 0">
                          Your confirmation number is:
                          {{ this.referencenumber }} <br />
                          Your credit card xxxxxxxxxxx{{ this.cardlastfour }}
                          has been charged
                          {{ this.money(this.chargedamount) }}.
                        </p>

                        <div class="mt-4">
                          <div>
                            <a :href="this.invoicelink" class="btn btn-primary me-2" v-if="orderTotal > 0">
                              View &amp; Print Paid Invoice
                            </a>

                            <div class="cancel d-inline">
                              <a class="btn btn-primary" target="_blank" @click.prevent="showAddPressRelease">
                                Add Press Release
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 card card-md" v-else-if="saveSuccess">
                    <div class="card-status-top bg-success"></div>
                    <div class="card-body">
                      <div class="col-12">
                        <p class="h3">Update Successful</p>
                        <p>
                          Your press release has been updated and should now
                          appear on your company profile in the directories you
                          selected.
                        </p>
                        <div class="text-left">
                          <button class="btn btn-primary" @click.prevent="showAddPressRelease">
                            Add Press Release
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 card card-md" v-else-if="saveError">
                    <div class="card-status-top bg-danger"></div>
                    <div class="card-body">
                      <div class="col-md-12">
                        <div class="col-md-6">
                          <p class="h3">
                            We are unable to save your press release at this
                            time. Please contact Customer Service at
                            1.866.627.2467 or email
                            <a href="mailto:advertisersupport@mediabrains.com">advertisersupport@mediabrains.com</a>.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 d-flex" v-else>
                    <div class="col-5 mb-1">
                      <p class="h2">Post Your Press Release</p>
                      <p>
                        Please select a Directory to post your Press Release
                      </p>

                      <div v-if="siteList.length > 0">
                        <div class="pb-2">
                          <div class="form-check" v-for="site in siteList" v-bind:key="site.value">
                            <input class="form-check-input" type="checkbox" @change="check(filter.selected_sites)"
                              v-model="filter.selected_sites" :id="site.value" :value="site.value" />
                            <label class="form-check-label" :for="site.value">{{
                site.text
              }}</label>
                          </div>
                        </div>
                        <div class="text-start">
                          <div>
                            <h3 class="d-inline">Order Total</h3>
                            {{ orderTotal > 0 ? money(orderTotal) : "------" }}
                          </div>
                          <div class="mt-3">
                            <button :disabled="displayCheckout" @click.prevent="clickaction"
                              class="btn btn-block btn-primary" v-if="(orderTotal == 0 &&
                filter.selected_sites.length > 0) ||
                maxordertotal == 0
                ">
                              <span class="spinner-border spinner-border-sm me-2" role="status"
                                v-if="this.isLoading"></span>
                              {{ this.saveuploadmsg }}
                            </button>
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <span> No sites available. </span>
                      </div>
                    </div>

                    <div class="col-7">
                      <checkout-form :submitting-payment="submittingPayment" :success="paymentSuccess"
                        :error="paymentError" :label="`Purchase Press Release`" v-on:update:checkout-card="checkout"
                        v-if="(maxordertotal > 0 && orderTotal > 0) ||
                (filter.selected_sites.length == 0 &&
                  maxordertotal > 0)
                "></checkout-form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card card-md">
            <div class="card-body">
              <div class="row">
                <div v-if="reload">
                  <div class="spinner-border mt-2" role="status"></div>
                </div>
                <p :class="[
                'h2',
                displayPressReleaseDTOs.length > 0 ? 'mb-4' : '',
              ]">
                  Posted Press Releases
                </p>
                <div class="table-responsive" v-if="displayPressReleaseDTOs.length > 0">
                  <table class="table table-vcenter card-table table-striped">
                    <thead>
                      <tr>
                        <th class="text-center">
                          <a href="#" @click.prevent="sort(`displayDate`)">Date Posted</a>
                        </th>
                        <th class="text-center">
                          <a href="#" @click.prevent="sort(`headLine`)">Headline</a>
                        </th>
                        <th class="text-center">
                          <a href="#" @click.prevent="">Edit</a>
                        </th>

                        <th class="text-center">
                          <a href="#" @click.prevent="">Delete</a>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="pressreleaseDTO in displayPressReleaseDTOs" v-bind:key="pressreleaseDTO.rowid">
                        <td>
                          <div class="text-left text-nowrap">
                            {{ pressreleaseDTO.displayDate }}
                          </div>
                        </td>
                        <td>
                          <div class="text-left text-nowrap">
                            {{ pressreleaseDTO.headLine }}
                          </div>
                        </td>
                        <td>
                          <div class="text-center text-nowrap">
                            <button @click="
                editPressRelease(pressreleaseDTO.contentID)
                " class="btn btn-block btn-primary">
                              Edit
                            </button>
                          </div>
                        </td>
                        <td>
                          <div class="text-center text-nowrap">
                            <button @click="
                deletePressRelease(pressreleaseDTO.contentID)
                " class="btn btn-block btn-primary">
                              Delete
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div v-else>
                  <span v-if="filter.selected_datefilter != ''">
                    No press releases exist for the dates selected.
                  </span>
                  <span v-else>
                    Your company does not have any Press Releases.
                  </span>
                </div>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-xl" v-else>
        <div class="row">
          <div class="col">
            <h3>Loading press releases...</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.table .text-right {
  text-align: right;
}

.table .text-left {
  text-align: left;
}

.table .text-center {
  text-align: center;
}

.table-responsive {
  margin-bottom: 0px;
}

.alert {
  padding-top: 0.4375rem;
  padding-right: 1rem;
  padding-bottom: 0.4375rem;
  padding-left: 1rem;
}
</style>

<script>
import PressReleaseService from "../services/PressReleaseService";
import TinyMce from "../components/TinyMce/TinyMce.vue";
import CheckoutForm from "../components/Upgrade/CheckoutForm";
import TrackingService from "../services/TrackingService";

import { format, isBefore, parseISO } from "date-fns"; //parseISO format isBefore

export default {
  name: "PressRelease",
  components: {
    TinyMce,
    // FileUpload,
    CheckoutForm,
  },
  data: () => {
    return {
      loaded: false,
      reload: false,
      sortdir: "asc",
      sortcol: "displayDate",
      contentid: "",
      pressReleaseDTOs: [],
      pressReleaseSiteDTOs: [],
      siteList: [],
      existingSites: [],
      orderTotal: 0,
      headline: "",
      formErrors: {},
      fileHtml: "",
      fileError: "",
      date_filters: ["Year To Date", "Month To Date", "Today", "Custom"],
      custom: false,
      rows: 0,
      submittingPayment: false,
      paymentSuccess: false,
      paymentError: false,
      editpressrelease: false,
      isLoading: false,
      deleteSuccess: false,
      saveSuccess: false,
      saveError: false,
      showForm: true,
      checkoutCard: {},
      referencenumber: "",
      chargedamount: 0,
      cardlastfour: "",
      invoicelink: "",
      saveuploadmsg: "Upload Free Press Release",
      maxordertotal: 0,
      filter: {
        selected_sites: [],
        selected_productids: [],
        selected_datefilter: "",
        selected_startdate: "",
        selected_enddate: "",
      },
    };
  },
  methods: {
    getFormattedDate(thedate) {
      let year = new Date(thedate).getFullYear();
      let month = (1 + new Date(thedate).getMonth())
        .toString()
        .padStart(2, "0");
      let day = new Date(thedate).getDate().toString().padStart(2, "0");
      return month + "/" + day + "/" + year;
    },
    sort: function (s) {
      //if s == current sort, reverse
      if (s === this.sortcol) {
        this.sortdir = this.sortdir === "asc" ? "desc" : "asc";
      }
      this.sortcol = s;
    },
    money(amount) {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });

      return formatter.format(amount);
    },
    validate(item) {
      if (item === "all" || item === "headline") {
        if (this.headline == "" || this.headline == null) {
          this.$set(this.formErrors, "headline", true);
        } else {
          this.$set(this.formErrors, "headline", false);
        }
      }
      if (item === "all" || item === "body") {
        if (this.fileHtml == "" || this.fileHtml == null) {
          this.$set(this.formErrors, "body", true);
        } else {
          this.$set(this.formErrors, "body", false);
        }
      }
    },
    validatetinymce(isvalid) {
      if (!isvalid) {
        this.$set(this.formErrors, "body", true);
      } else {
        this.$set(this.formErrors, "body", false);
      }
    },
    showAddPressRelease() {
      (this.submittingPayment = false),
        (this.paymentSuccess = false),
        (this.paymentError = false),
        (this.editpressrelease = false),
        (this.isLoading = false),
        (this.deleteSuccess = false),
        (this.saveSuccess = false),
        (this.saveError = false),
        (this.showForm = true),
        (this.postError = "");
      this.postResult = [];
      this.headline = "";
      this.fileHtml = " ";
      this.filter.selected_sites = [];
      this.filter.selected_productids = [];
      this.saveuploadmsg = "Upload Free Press Release";
      window.scrollTo(0, 0);
    },
    async clickaction() {
      if (this.editpressrelease) {
        await this.update();
      } else {
        await this.checkout();
      }
    },

    async setIsLoading() {
      this.isLoading = true;
    },

    async getPressReleaseSites() {
      let company = this.$auth.activeCompany;

      let pressReleaseSiteFilter = {
        Company: { Companyid: company.companyId },
      };

      // clear selected sites
      this.filter.selected_sites = [];
      this.filter.selected_productids = [];
      this.siteList = [];

      // clear check box list
      this.pressReleaseSites = [];

      // make service call
      let pressReleaseSiteDTOs = await PressReleaseService.getPressReleaseSites(
        pressReleaseSiteFilter
      );

      // interate through each site
      pressReleaseSiteDTOs.forEach((n) => {
        // Add site to pressReleaseSites
        this.siteList.push({
          text: n.siteName + " (" + (n.cost == 0 ? "Free" : "$" + n.cost) + ")",
          value: n.siteId,
        });

        // Calculate max order total
        this.maxordertotal += n.cost;
      });

      //save existing press release sites
      this.existingSites = pressReleaseSiteDTOs;
    },
    async getPressReleaseData(isreload) {
      let company = this.$auth.activeCompany;

      let pressReleaseFilter = {
        Company: { Companyid: company.companyId },
        StartDate: this.filter.selected_startdate,
        EndDate: this.filter.selected_enddate,
      };

      // mark as reload or not
      this.reload = isreload;

      // make service call
      let pressReleaseDTOs = await PressReleaseService.getPressReleases(
        pressReleaseFilter
      );

      // interate through press releases
      pressReleaseDTOs.forEach((n) => {
        // incrment rows
        this.rows++;

        // format sentdate
        n.displayDate = format(parseISO(n.displayDate), "MM/dd/yyyy HH:mm:ss");

        // build rowid
        n.rowid = this.rows;
      });

      // sort by sitename
      this.pressReleaseDTOs = pressReleaseDTOs.sort((a) => a.siteName);

      // mark as loaded
      this.loaded = true;

      // mark as reloaded
      this.reload = false;
    },
    async checkout(card) {
      this.isLoading = true;

      this.checkoutCard = card;

      let company = this.$auth.activeCompany;

      this.validate("all");

      let formHasErrors = Object.values(this.formErrors).some((i) => i);

      let PressReleaseCheckoutDTO = {
        company: company,
        headline: this.headline,
        body: this.fileHtml,
        contentid: this.contentid ? this.contentid : null,
        totalamount: JSON.stringify(this.orderTotal),
        productids: this.filter.selected_productids,
        card: card,
      };

      this.submittingPayment = true;

      if (!formHasErrors) {
        try {
          TrackingService.track("add press release", {
            detail: this.headline,
          });

          let paymentResult = await PressReleaseService.checkout(
            PressReleaseCheckoutDTO
          );

          if (paymentResult.success) {
            this.paymentSuccess = true;
            this.submittingPayment = false;
            this.showForm = false;
            await this.$auth.identify();
            this.chargedamount = paymentResult.chargedAmount;
            this.cardlastfour = paymentResult.lastFour;
            this.referencenumber = paymentResult.referenceNumber;
            this.invoicelink = paymentResult.invoiceLink;
            await this.getPressReleaseSites();
            await this.getPressReleaseData(true);

            // force window scroll
            window.scrollTo(0, 0);
          } else {
            this.paymentSuccess = false;
            this.submittingPayment = false;
            this.paymentError = true;
            this.saveError = true;
          }
        } catch (e) {
          this.paymentSuccess = false;
          this.submittingPayment = false;
          this.paymentError = true;
          this.saveError = true;
        }
      }

      // turn off loading
      this.isLoading = false;
    },
    async update() {
      let company = this.$auth.activeCompany;

      // clear values
      this.saveSuccess = false;
      this.paymentSuccess = false;

      let PressReleaseSaveDTO = {
        company: company,
        contentid: this.contentid,
        headline: this.headline,
        body: this.fileHtml,
        productids: this.filter.selected_productids,
      };

      try {
        TrackingService.track("update press release", {
          detail: PressReleaseSaveDTO.headline,
        });

        let postResult = await PressReleaseService.updatePressRelease(
          PressReleaseSaveDTO
        );
        if (postResult.success) {
          this.saveSuccess = true;
          this.showForm = false;
          await this.getPressReleaseData(true);
        } else {
          this.saveSuccess = false;
          this.postError = true;
        }
      } catch (e) {
        console.log(e);
        this.saveSuccess = false;
        this.postError = true;
      }

      // clear
      this.isLoading = false;
      this.editpressrelease = false;

      // clear selected sites
      this.filter.selected_sites = [];
      this.filter.selected_productids = [];
    },
    async editPressRelease(contentid) {
      // clear values
      (this.submittingPayment = false), (this.postSuccess = false);
      this.paymentSuccess = false;
      this.saveSuccess = false;
      this.showForm = true;

      // change label
      this.saveuploadmsg = "Save Press Release";

      // get companyid
      let company = this.$auth.activeCompany;

      let pressReleaseFilterDTO = {
        Company: { Companyid: company.companyId },
        ContentId: contentid,
        StartDate: this.filter.selected_startdate,
        EndDate: this.filter.selected_enddate,
      };

      // get sites for contentid
      let pressReleaseDTO = await PressReleaseService.getPressRelease(
        pressReleaseFilterDTO
      );

      // set headline
      this.headline = pressReleaseDTO.headLine;

      // set fileHtml
      this.fileHtml = pressReleaseDTO.body;

      // build press release filter
      let PressReleaseFilterDTO = {
        company: company,
        contentid: JSON.stringify(contentid),
      };

      // get sites for contentid
      let pressReleaseSites = await PressReleaseService.getSitesForPressRelease(
        PressReleaseFilterDTO
      );

      // make sure sites found
      if (pressReleaseSites) {
        // clear orderTotal
        this.orderTotal = 0;

        // clear selected values
        this.filter.selected_productids = [];
        this.filter.selected_sites = [];

        // interate through matched pressrelease sites
        pressReleaseSites.forEach((n) => {
          // build list of selected sites (contentid could be tied to more than 1 site)
          if (!this.filter.selected_sites.includes(n.siteId)) {
            this.filter.selected_sites.push(n.siteId);
          }

          // get matching productid
          let productid = this.existingSites.filter(
            (a) => a.siteId == n.siteId
          )[0].productId;

          // build list of selected products (contentid could be tied to more than 1 product)
          if (!this.filter.selected_productids.includes(productid)) {
            this.filter.selected_productids.push(productid);
          }
        });
      }

      // set edit contentid
      this.contentid = contentid;

      // set editing flag
      this.editpressrelease = true;

      // set editor html in case new form load
      if (!this.$refs.body.editorValue) {
        this.$refs.body.setEditorValue(this.fileHtml);
      }

      // revalidate
      this.validate("all");

      // force window scroll
      window.scrollTo(0, 0);
    },
    async deletePressRelease(contentid) {
      let company = this.$auth.activeCompany;

      // clear values
      this.deleteSuccess = false;
      this.paymentSuccess = false;

      // set reload
      this.reload = true;

      let PressReleaseDeleteDTO = {
        company: company,
        contentId: JSON.stringify(contentid),
      };

      if (confirm("Are you sure that you want to delete this press release?")) {
        try {
          TrackingService.track("delete press release", {
            detail: this.headline,
          });

          let postResult = await PressReleaseService.deletePressRelease(
            PressReleaseDeleteDTO
          );
          if (postResult.success) {
            this.deleteSuccess = true;
            this.postError = false;
            await this.getPressReleaseSites();
            await this.getPressReleaseData(true);
          } else {
            this.deleteSuccess = false;
            this.postError = true;
          }
        } catch (e) {
          console.log(e);
          this.deleteSuccess = false;
          this.postError = true;
        }
      }

      // clear selected values
      this.reload = false;
    },
    check(selected_sites) {
      // Make sure there are selected siteids
      if (selected_sites) {
        // clear orderTotal
        this.orderTotal = 0;

        // clear selected productids
        this.filter.selected_productids = [];

        // get matching pressReleaseSiteDTOs records
        let output = this.existingSites.filter((a) =>
          selected_sites.includes(a.siteId)
        );

        // interate through matched pressrelease sites
        output.forEach((n) => {
          // calculate total
          this.orderTotal += n.cost;

          // build list of productids
          if (!this.filter.selected_productids.includes(n.productId)) {
            this.filter.selected_productids.push(n.productId);
          }
        });

        if (selected_sites.length > 0) {
          this.validate("all");
        }
      }
    },
  },
  computed: {
    filteredPressReleaseDTOs() {
      return this.pressReleaseDTOs
        .filter((n) => {
          if (this.filter.selected_sites.length == 0) return true;
          return (
            this.filter.selected_sites.indexOf(n.siteId) >= 0 ||
            this.filter.selected_sites.indexOf(n.siteId) == -1
          );
        })
        .sort((a, b) => {
          let modifier = 1;
          if (this.sortdir === "desc") modifier = -1;

          if (this.sortcol != "displayDate") {
            if (a[this.sortcol] < b[this.sortcol]) return -1 * modifier;
            if (a[this.sortcol] > b[this.sortcol]) return 1 * modifier;
          } else {
            if (isBefore(new Date(a[this.sortcol]), new Date(b[this.sortcol])))
              return -1 * modifier;
            if (!isBefore(new Date(a[this.sortcol]), new Date(b[this.sortcol])))
              return 1 * modifier;
          }
        });
    },
    displayPressReleaseDTOs() {
      return this.filteredPressReleaseDTOs;
    },
    displaySavePressRelease() {
      return this.savepressrelease;
    },
    displayCheckout() {
      return this.filter.selected_productids.length === 0
    },
    displayLoading() {
      return this.isLoading;
    }
  },
  async mounted() {
    // load press release site data for company
    this.getPressReleaseSites();

    // load press release data for company
    this.getPressReleaseData(false);

    TrackingService.track("viewed press releases");
  },
};
</script>
